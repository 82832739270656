import { BehaviorSubject } from 'rxjs';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('sufra_user')));
const url = "https://uat.sufra-nwl.org.uk/app"

const AuthService = {
    login: function(email, password) {
      return fetch(`${url}/users/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email,
          password: password
        })
      }).then(response => {
        if (response.ok) {
          return response.json();
        }
        else {
          return Promise.reject();
        }
      }).then(user => {
          localStorage.setItem("sufra_user", JSON.stringify(user))
          currentUserSubject.next(user);
      })
    },

    register: function(name, email, password) {
      return fetch(`${url}/users/sign-up`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: name,
          email: email,
          password: password
        })
      })
    },

    logout: function() {
      localStorage.removeItem("sufra_user");
      currentUserSubject.next(null);
    },

    getAccessToken: function() {
      return currentUserSubject?.value?.accessToken
    },

    subscribe: function(handler) {
      currentUserSubject.asObservable().subscribe(handler)
    }
}

export default AuthService
